import React, { useEffect, useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import homebranch from "../Images/Contact/home branch.webp"
import branch2 from "../Images/Contact/branch2.jpeg"
import branch3 from "../Images/Contact/branch 3.avif"
import line from "../Images/Contact/curved-lined-arrow.png"
// import join from "../Images/About/join us.png"
import Whatsapp from '../Utilities/Whatsapp'

const Contact = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const [name, setName] = useState('') // useState to store First Name
    const [email, setEmail] = useState('') // useState to store Email address of the user
    const [mobile, setMobile] = useState('') // useState to store Mobile Number
    const [post, setPost] = useState('') // useState to store subject of the user
    const [message, setMessage] = useState('') // useState to store subject of the user
    const form = useRef();

    function validateForm() {


        if (name.length === 0) {
            alert('Invalid Form, Name can not be empty');
            return
        }
        if (email.length === 0) {
            alert('Invalid Form, Email Address can not be empty');
            return
        }

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
            alert('Please enter a valid email address');
            return
        }

        if (message.length === 0) {
            alert('Invalid Form, Message can not be empty');
            return
        }

        if (typeof mobile !== "undefined") {

            var pattern = new RegExp(/^[0-9\b]+$/);

            if (!pattern.test(mobile)) {
                alert("Please enter only number.");
                return
            } else if (mobile.length !== 10) {
                alert("Please enter valid phone number.");
                return
            }

        }

    }
    const sendEmail = (e) => {
        e.preventDefault();
        // service_id, templte_id and public key will get from Emailjs website when you create account and add template service and email service 
        emailjs.sendForm('service_39yn67f', 'template_nc1vq04', e.target,
            '9MnBiE7Mx9H96KTc9')
            .then((result) => {
                console.log(result)
                alert("Sent Successfully");
            }, (error) => {
                console.log(error)
                alert("oops! some error occured");
            });
    };
    return (
        <div>
            <Header />
            <Whatsapp />
            <section className="contact-page">
                <h3 className="page-title">
                    {/* Welcome to Dwarka School of Music */}
                </h3>
                <div className="contact-body">

                    <div className="form">

                        <div className="form-body">
                            <span>contact</span>
                            <h3>Learn The Music From The Core & Become Master</h3>
                            <p>Have questions or need assistance? We're here to help! Contact us today, and our team will get back to you promptly.</p>

                            <div className="form-ep">
                                <div className="phone">
                                    <div className="svg">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 32 32"><g id="Layer_3" data-name="Layer 3"><path d="M16,1A15,15,0,1,0,31,16,15,15,0,0,0,16,1Zm.643,9.214a5.149,5.149,0,0,1,5.143,5.143H20.5A3.861,3.861,0,0,0,16.643,11.5ZM20.808,25c-.039,0-3.9-.066-8.865-4.943C7.066,15.094,7,11.231,7,11.192,7,10.711,10,7,10.663,7c.23,0,.409.156.605.39.472.562,2.559,3.856,2.463,4.405-.046.258-.265.454-.893.981a7.416,7.416,0,0,0-.866.8,6.906,6.906,0,0,0,.566,1.526A9.435,9.435,0,0,0,16.9,19.462a6.906,6.906,0,0,0,1.526.566,7.416,7.416,0,0,0,.8-.866c.527-.628.723-.847.981-.893.549-.1,3.843,1.991,4.405,2.463.234.2.39.375.39.605C25,22,21.289,25,20.808,25Zm2.906-9.643a7.079,7.079,0,0,0-7.071-7.071V7A8.367,8.367,0,0,1,25,15.357Z" /></g></svg>
                                    </div>
                                    <h3>Phone:</h3>
                                    <a href="tel:918287202085">+91 82872 02085</a><br />
                                    <a href="tel:911147091917">+91 11-47091917</a>
                                </div>
                                <div className="phone">
                                    <div className="svg">
                                        <svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m256 512c-141.159 0-256-114.841-256-256s114.841-256 256-256 256 114.841 256 256-114.841 256-256 256zm132.758-166.069c3.156 0 5.725-2.568 5.725-5.725v-160.717l-130.25 103.432c-2.41 1.915-5.323 2.872-8.234 2.872s-5.823-.958-8.234-2.872l-130.249-103.432v160.717c0 3.157 2.569 5.725 5.725 5.725zm-19.96-179.862-112.798 89.575-112.799-89.575zm52.168 5.725v168.414c0 17.759-14.449 32.208-32.208 32.208h-265.517c-17.76 0-32.207-14.449-32.207-32.208v-168.414c0-17.759 14.448-32.207 32.207-32.207h265.517c17.759-.001 32.208 14.448 32.208 32.207z" fill-rule="evenodd" /></svg>
                                    </div>
                                    <h3>Email:</h3>
                                    <a href="mailto:contact.dsom@gmail.com">contact.dsom@gmail.com</a>
                                </div>
                            </div>
                        </div>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="input">
                                <h3>Get In Touch</h3>
                            </div>
                            <div className="input">
                                <label for="name">Name:</label>
                                <input type="text" name="user_name"
                                    id="name" value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required />
                            </div>

                            <div className="input">
                                <label for="name">Mobile:</label>
                                <input type="text" name="user_phone"
                                    id="phone"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    required />
                            </div>

                            <div className="input">
                                <label for="name">Email:</label>
                                <input type="text" name="user_email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required />
                            </div>

                            <div className="input">
                                <label for="name">Select Class:</label>

                                <select id="" value={post} name='post' onChange={(e) => setPost(e.target.value)}>
                                    {/* <option value="Guitar" hidden>Select Class</option> */}
                                    <option value="drums">drums</option>
                                    <option value="guitar">guitar</option>
                                    <option value="Keyboard">Keyboard</option>
                                    <option value="vocal">vocal</option>
                                    <option value="dance">dance</option>
                                </select>

                            </div>

                            <div className="input">
                                <label for="name">Message:</label>
                                <textarea id="" name="user_message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required></textarea>
                                <button onClick={() => { validateForm() }}>Register Now <svg id="a8986d35-5ac7-435f-a6bd-af2a283e185d" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg" data-name="glyph"><path d="m8.30774 32.06146c1.88757.852 14.58234 4.84107 15.85034 6.22125l33.23468-33.23461a5.1042 5.1042 0 0 0 -4.94885-.69281l-45.12591 18.13378c-4.397 1.76111-3.20673 8.23536.98974 9.57239z" /><path d="m58.80664 6.4621c-1.09705 1.1004-32.11945 32.11725-33.23464 33.23474 1.36469 1.2019 5.39233 14.04516 6.22131 15.84967 1.69733 3.69482 7.63288 5.80713 9.55115 1.01153l18.16213-45.14004a5.11952 5.11952 0 0 0 -.69995-4.9559z" /><path d="m13.85565 37.2818a.99993.99993 0 0 0 -1.41394 0l-8.00871 8.01074a.99983.99983 0 0 0 1.41394 1.414l8.00867-8.01074a1 1 0 0 0 .00004-1.414z" /><path d="m25.22479 49.94141-8.3421 8.34411a1 1 0 0 0 1.414 1.41394l8.342-8.34411a1 1 0 0 0 -1.4139-1.41394z" /><path d="m13.537 48.92578-6.96925 6.96094a.99983.99983 0 1 0 1.41325 1.41467l6.96924-6.961a.99981.99981 0 0 0 -1.41324-1.41461z" /></svg></button>
                            </div>

                        </form>
                    </div>

                    <div className="location">
                        {/* <span>Location</span> */}
                        <h3>Our Location</h3>

                        <div className="location-body">
                            <div className="location-l">
                                <div className="location-card">
                                    <img src={homebranch} alt="Dwarka School Of Music" />
                                    <span>Home Branch</span>
                                    <div className="location-text">
                                        <h4>Dwarka School Of Music</h4>
                                        <p><b>Address:</b> E-1083, 1st Floor, MPS Plaza, Ramphal Chowk Rd,Sector 7, Dwarka, New Delhi, Delhi 110075</p>
                                        <p><b>Phone:</b> +91 89202 46217</p>
                                    </div>
                                    <img src={line} alt="line" className="line" />
                                </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.4866690042927!2d77.06933447495503!3d28.58517348623068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b1636452edb%3A0xabd7d2e066dbca55!2sDwarka%20School%20of%20Music!5e0!3m2!1sen!2sin!4v1733322610561!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className="location-l">
                                <div className="location-card">
                                    <img src={branch2} alt="Dwarka School Of Music" />
                                    <span>After School Branch</span>

                                    <div className="location-text">
                                        <h4>Udayan Kidz Pre School</h4>
                                        <p><b>Address:</b> 53, pocket 10, Dwarka sector 13</p>
                                        <p><b>Phone:</b> +91 93157 44774</p>
                                    </div>
                                    <img src={line} alt="line" className="line" />
                                </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.0300207891514!2d77.03244717495551!3d28.59887618560914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b878d12ea95%3A0x7e05369507bcaeb!2sUdayan%20Kidz%20Pre%20School!5e0!3m2!1sen!2sin!4v1733375314277!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className="location-l">
                                <div className="location-card">
                                    <img src={branch3} alt="Dwarka School Of Music" />
                                    <span>After School Branch</span>

                                    <div className="location-text">
                                        <h4>Global Pratibha International School</h4>
                                        <p><b>Address:</b> 136-A, A Block, Bagdola, Sector 8 Dwarka, New Delhi, Delhi 110077</p>
                                        <p><b>Phone:</b> +91 89292 91871</p>
                                    </div>
                                    <img src={line} alt="line" className="line" />
                                </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.886172979145!2d77.07144217495456!3d28.573180586774413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b9679336125%3A0x7c43cf7b2226cc4a!2sGlobal%20Pratibha%20International%20School!5e0!3m2!1sen!2sin!4v1733375387381!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>


    )
}

export default Contact