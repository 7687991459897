import React, { useEffect, useState } from 'react'
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import { Link } from 'react-router-dom'
import image1 from "../../Images/Blog/How After-School Music and Dance Education Can Benefit Students and Parents.png"
import image2 from "../../Images/Blog/How Learning Music Enriches Your Life.png"
import image3 from "../../Images/Blog/How to Choose the Perfect Instrument for Your Child.png"
import Whatsapp from '../../Utilities/Whatsapp'

import "./Blog.scss"
const Blog = () => {

    const [blogs, Setblogs] = useState([]);

    useEffect(() => {
        // 👇️ scroll to top on page load

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });


    }, []);


    return (
        <div>
            <Header />
            <Whatsapp />
            <div className="blog">
                <h1>Blogs</h1>
                <div className="blog-body">
                    <div className="blog-body-content">
                        {/* {blogs.map(blog => ( */}
                        <Link className="blog-card" to={"/How-After-School-Music-and-Dance-Education-Can-Benefit-Students-and-Parents"}>
                            <img src={image1} />
                            <div className="blog-card-text">
                                {/* <span>
                                        {blog.Date}
                                    </span> */}

                                <h3>How After-School Music and Dance Education Can Benefit Students and Parents</h3>
                            </div>
                        </Link>
                        <Link className="blog-card" to={"/The-Harmonious-Impact-How-Learning-Music-Enriches-Your-Life"}>
                            <img src={image2} />
                            <div className="blog-card-text">
                                {/* <span>
                                        {blog.Date}
                                    </span> */}

                                <h3>The Harmonious Impact: How Learning Music Enriches Your Life
                                </h3>
                            </div>
                        </Link>
                        <Link className="blog-card" to={"/How-to-Choose-the-Perfect-Instrument-for-Your-Child"}>
                            <img src={image3} />
                            <div className="blog-card-text">
                                {/* <span>
                                        {blog.Date}
                                    </span> */}

                                <h3>How to Choose the Perfect Instrument for Your Child </h3>
                            </div>
                        </Link>
                        <hr />
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Blog
