import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import i1 from '../Images/Gallery/Infrastructure/1.webp'
import i2 from '../Images/Gallery/Infrastructure/2.webp'
import i3 from '../Images/Gallery/Infrastructure/3.webp'
import i5 from '../Images/Gallery/Infrastructure/12.webp'
import i6 from '../Images/Gallery/Infrastructure/6.webp'
import i7 from '../Images/Gallery/Infrastructure/7.webp'
import i8 from '../Images/Gallery/Infrastructure/8.webp'
import i9 from '../Images/Gallery/Infrastructure/9.webp'
import i10 from '../Images/Gallery/Infrastructure/10.webp'
import i11 from '../Images/Gallery/Infrastructure/11.webp'
import s1 from '../Images/Gallery/Students/1.jpg'
import s2 from '../Images/Gallery/Students/2.jpg'
import s3 from '../Images/Gallery/Students/3.jpg'
import s4 from '../Images/Gallery/Students/4.jpg'
import s5 from '../Images/Gallery/Students/5.jpg'
import s6 from '../Images/Gallery/Students/6.jpg'
import s7 from '../Images/Gallery/Students/7.jpg'
import s8 from '../Images/Gallery/Students/8.jpg'
import s9 from '../Images/Gallery/Students/9.jpg'
import s10 from '../Images/Gallery/Students/10.jpg'
import s11 from '../Images/Gallery/Students/11.jpg'
import s12 from '../Images/Gallery/Students/12.jpg'
import s13 from '../Images/Gallery/Students/13.jpg'
import s14 from '../Images/Gallery/Students/14.jpg'
import a1 from '../Images/Gallery/Achievements/1.jpg'
import a2 from '../Images/Gallery/Achievements/2.jpg'
import a3 from '../Images/Gallery/Achievements/3.webp'
import a4 from '../Images/Gallery/Achievements/4.webp'
import a5 from '../Images/Gallery/Achievements/5.webp'
import a6 from '../Images/Gallery/Achievements/1.jpg'
import a7 from '../Images/Gallery/Achievements/2.jpg'
import a8 from '../Images/Gallery/Achievements/3.jpg'
import a9 from '../Images/Gallery/Achievements/4.jpg'
import a10 from '../Images/Gallery/Achievements/5.jpg'
import a11 from '../Images/Gallery/Achievements/6.jpg'
import a12 from '../Images/Gallery/Achievements/7.jpg'
import a13 from '../Images/Gallery/Achievements/8.jpg'
import a14 from '../Images/Gallery/Achievements/9.jpg'
import a15 from '../Images/Gallery/Achievements/10.jpg'
import a16 from '../Images/Gallery/Achievements/11.jpg'
import a17 from '../Images/Gallery/Achievements/12.jpg'
import Whatsapp from '../Utilities/Whatsapp'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { useEffect } from "react"
import { useState } from 'react'

const Gallery = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const images = [
        { id: 7, src: a1, category: "achievements" },
        { id: 8, src: a2, category: "achievements" },
        { id: 9, src: a3, category: "achievements" },
        { id: 10, src: a4, category: "achievements" },
        { id: 11, src: a5, category: "achievements" },
        { id: 12, src: a6, category: "achievements" },
        { id: 13, src: a7, category: "achievements" },
        { id: 1, src: i1, category: "infrastructure" },
        { id: 2, src: i11, category: "infrastructure" },
        { id: 3, src: i5, category: "infrastructure" },
        { id: 4, src: i6, category: "infrastructure" },
        { id: 5, src: i8, category: "infrastructure" },
        { id: 6, src: i9, category: "infrastructure" },

        { id: 14, src: s1, category: "students" },
        { id: 15, src: s2, category: "students" },
        { id: 16, src: s3, category: "students" },
        { id: 17, src: s4, category: "students" },
        { id: 18, src: s5, category: "students" },
        { id: 19, src: s6, category: "students" },
        { id: 20, src: s7, category: "students" },
        { id: 21, src: s8, category: "students" },
        { id: 22, src: s9, category: "students" },
        { id: 23, src: s10, category: "students" },
        { id: 24, src: s11, category: "students" },
        { id: 25, src: s12, category: "students" },
        { id: 26, src: s13, category: "students" },
        { id: 27, src: s14, category: "students" },
        { id: 28, src: a8, category: "achievements" },
        { id: 29, src: a9, category: "achievements" },
        { id: 30, src: a10, category: "achievements" },
        { id: 31, src: a11, category: "achievements" },
        { id: 32, src: a12, category: "achievements" },
        { id: 33, src: a14, category: "achievements" },
        { id: 34, src: a16, category: "achievements" },

    ];

    const categories = ["all", "infrastructure", "students", "achievements"];

    const [activeCategory, setActiveCategory] = useState("all");

    const handleFilterClick = (category) => {
        if (activeCategory === category) {
            setActiveCategory("all"); // Reset to show all images
        } else {
            setActiveCategory(category);
        }
    };

    const filteredImages =
        activeCategory === "all"
            ? images
            : images.filter((image) => image.category === activeCategory);


    return (
        <div>
            <Header />
            <Whatsapp/>
            <div id='pages-gallery'>
                <div className="pages-gallery">
                    <h1>Display  <span>Area</span></h1>
                    <div className="gallery-filter">
                        {categories.map((category) => (
                            <button
                                key={category}
                                className={`filter-button ${activeCategory === category ? "active" : ""
                                    }`}
                                onClick={() => handleFilterClick(category)}
                            >
                                {category}
                            </button>
                        ))}
                    </div >
                    <div className="pages-gallery-images">

                        {filteredImages.map((image) => (
                            <Zoom key={image.id} className="gallery-item">
                                <img key={image.id} src={image.src} alt={image.category} className="gallery-image" />
                            </Zoom>
                        ))}
                    </div>
                </div>
            </div >
            <Footer />

        </div>
    )
}

export default Gallery
