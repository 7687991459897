import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import himanshu from "../Images/Team/himanshu.webp"
import siddhant from "../Images/Team/sidhant.webp"
import manoj from "../Images/Team/manoj.webp"
import vinay from "../Images/Team/vinay verma.jpg"
import Whatsapp from '../Utilities/Whatsapp'

import dipahree from "../Images/Team/Dipashree Chaterjee.png"
import { useEffect } from "react"

const Our_Team = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Header />
      <Whatsapp />
      <div className="pages-team">
        <h1>Our  <span>staff</span></h1>
        <div className='pages-team-body'>
          <div class="pages-team-img">
            <img src={himanshu} alt="himanshu" />
          </div>
          <div className="pages-team-text">
            <h2>Himanshu sharma</h2>
            <h6>Guitar and keys</h6>
            <p>I'm a professional lead guitarist with over 7 years of experience working with some of the most famous artists like Tulsi Kumar, Millind Gaba, Karan Sehmbi & many more. Music is my passion, and I love nothing more than creating unforgettable experiences on stage with my guitar.
              Currently rocking stages with the King of Pop - Daler Mehndi as a Lead Guitarist.</p>
            <p><i style={{ color: '#eaefa2' }}>Qualifications:</i>&nbsp; 12th passed from NIOS</p>
            <p><i style={{ color: '#eaefa2' }}>Certifications:</i>&nbsp; Rockschool grade 8 in electric guitar, senior diploma in vocals from Prayag Sangeet Samiti</p>

            <ul>
              <li><a target='_blank' href="https://instagram.com/himaanshh_music?igshid=MmIzYWVlNDQ5Yg=="><i class="bi bi-instagram"></i></a></li>
              <li><a target='_blank' href="https://www.facebook.com/himanshh.sharma/"><i class="bi bi-facebook"></i></a></li>
            </ul>
          </div>
        </div>

        {/* <div className='pages-team-body'>
          <div class="pages-team-img">
            <img src={siddhant} alt="himanshu" />
          </div>
          <div className="pages-team-text">
            <h2>Siddhant</h2>
            <h6>  Piano and Guitar</h6>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officiis officia aperiam nisi repellat animi laboriosam veniam in asperiores ipsam, quos quod illo quidem perspiciatis praesentium nobis perferendis, veritatis, accusantium sequi!</p>
            <p><i style={{ color: '#eaefa2' }}>Qualifications:</i> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
            <p><i style={{ color: '#eaefa2' }}>Certifications:</i> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>

            <ul>
              <li><a href=""><i class="bi bi-instagram"></i></a></li>
              <li><a href=""><i class="bi bi-facebook"></i></a></li>
            </ul>
          </div>
        </div> */}

        <div className='pages-team-body'>
          <div class="pages-team-img">
            <img src={manoj} alt="himanshu" />
          </div>
          <div className="pages-team-text">
            <h2>Manoj kumar</h2>
            <h6>Guitar and Drum</h6>
            <p>I've been teaching guitar for over 18 years now, and drums for more than 11 years. Music is my passion, and I love sharing my knowledge with students of all levels.
              Whether you're a complete beginner who's never touched an instrument, or a seasoned player looking to refine your skills, I can help you achieve your musical goals.</p>
            <p><i style={{ color: '#eaefa2' }}>Qualifications:</i> Graduate from Prayag Sangeet Samiti Prayagraj</p>
            <p><i style={{ color: '#eaefa2' }}>Certifications:</i> Grade 8 Guitar Rockschool London, Grade 8 Drum Rockschool London</p>

            <ul>
              <li><a href="https://www.instagram.com/manojk_music?igsh=MTllZTNubTduaGtvcw%3D%3D&utm_source=qr">
                <i class="bi bi-instagram"></i></a></li>
              <li><a href="https://www.facebook.com/profile.php?id=100001075862591">
                <i class="bi bi-facebook"></i></a>
              </li>
            </ul>
          </div>
        </div>

        <div className='pages-team-body'>
          <div class="pages-team-img">
            <img src={dipahree} alt="himanshu" />
          </div>
          <div className="pages-team-text">
            <h2>Dipasree Chaterjee</h2>
            <h6>Dance</h6>
            <p>I've been passionate about dance for many years, and I love sharing that passion with my students. My experience stretches back to 2006, when I began working as a choreographer at Shiksha Bharti International School in New Delhi. Since then, I've had the privilege of teaching at a variety of schools in the Delhi area, including Tender Feet, Saraswati Anchal Sagarpur, Perfect Foundation, Nav Gayan Deep, Sr. SEC Public School, and most recently, Delhi International School.
              Currently rocking stages with the King of Pop - Daler Mehndi as a Lead Guitarist.</p>
            <p><i style={{ color: '#eaefa2' }}>Qualifications:</i> Dance coach in Delhi International School</p>
            <p><i style={{ color: '#eaefa2' }}>Certifications:</i> Winner Of big MemSaab on Big Magic, 2nd Runner up Trophy winner form Dance India Dance Super Moms Season-2 </p>


            <ul>
              <li><a target='_blank' href="https://www.instagram.com/dipasreechatterjee?igsh=cTJjOTdzaWRnbjM5&utm_source=qr"><i class="bi bi-instagram"></i></a></li>
              <li><a target='_blank' href="https://www.facebook.com/profile.php?id=100009226089463&mibextid=LQQJ4d"><i class="bi bi-facebook"></i></a></li>
            </ul>
          </div>
        </div>

        <div className='pages-team-body'>
          <div class="pages-team-img">
            <img src={vinay} alt="himanshu" />
          </div>
          <div className="pages-team-text">
            <h2>Vijay Verma
            </h2>
            <h6>Guitar instructor

            </h6>
            <p>I excel at creating interactive and engaging lessons for students of all levels. My expertise ensures that each student receives personalized instruction to help them succeed and enjoy their musical journey.</p>
            <p><i style={{ color: '#eaefa2' }}>Qualifications:</i> Graduated from DU
            </p>
            <p><i style={{ color: '#eaefa2' }}>Certifications:</i>&nbsp;Best teacher award 2022, Best teacher award 2023

            </p>


            <ul>
              <li><a target='_blank' href="https://www.instagram.com/vijay.music01?igsh=bWdyano2enBxN3Bi
"><i class="bi bi-instagram"></i></a></li>
              {/* <li><a target='_blank' href="https://www.facebook.com/profile.php?id=100009226089463&mibextid=LQQJ4d"><i class="bi bi-facebook"></i></a></li> */}
            </ul>
          </div>
        </div>

      </div>



      <Footer />
    </div>
  )
}

export default Our_Team
